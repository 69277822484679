<template>
    <coll-mount :typePage="typePage"></coll-mount>
</template>

<script>
import CollMount from "@/views/modules/collection/business/mount/collMount.vue";

export default {
    name: "mountRecord",
    components: {CollMount},
    data(){
        return {
            typePage:'mountRecord'
        }
    },
}
</script>

<style scoped>

</style>